import Exporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';

import * as React from 'react';
import ReactDOM from 'react-dom/client';
// import "../styles/application";
// import './application.css';

import { App } from './components/App';
import EpsChart from './components/EpsChart';

Exporting(Highcharts);

document.addEventListener('DOMContentLoaded', () => {
  // 予想EPS
  if (document.querySelector('#eps_estimates-index')) {
    renderEpsChart();
  }

  const elm = document.querySelector('#stocks-show #code');

  // 予想PER, PBR, FCF倍率
  if (elm) {
    const code = elm.textContent;

    renderStockCharts(code);
  }
});

function renderStockCharts(code) {
  if (code === '10000') {
    // 全銘柄（10000）の予想PER
    renderStockChart('per', code);
  }

  const tabs = document.querySelectorAll('#stock-tabs a');

  tabs.forEach(function (tab) {
    tab.addEventListener('click', function () {
      const id = this.id;

      ['per', 'pbr', 'fcf-ratio'].forEach((type) => {
        const tab = `tab-${type}`;

        if (tab === id) {
          const selector = `#${type}-current`;
          const chart = document.querySelector(selector);

          if (chart) {
            return;
          }

          // 予想PER, PBR, FCF倍率
          renderStockChart(type, code);
        }
      });
    });
  });
}

function renderStockChart(type, code) {
  const elm = document.querySelector(`#${type}-chart`);

  ReactDOM.createRoot(elm).render(<App code={code} indices={type} />);
}

function renderEpsChart() {
  // 日経
  ReactDOM.createRoot(document.querySelector('#n225-chart')).render(<EpsChart code={'998407'} />);
}
